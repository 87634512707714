<template>
  <div>
  <div class="table-container">
      <h3 class="table-title">Users</h3>
      <div class="table-toolbar">
        <el-input v-model="search" prefix-icon="el-icon-search" @input="handleSearch" size="small"
          style="width: 300px; flex: 1 1 0%; margin-right: 0.5rem;" placeholder="Search By Name..." />
        <el-button style="flex: 0 1 0%;" size="small" @click="openCreateUserModal()" type="warning">
          New
        </el-button>
      </div>
    </div>
  <el-table
    :data="data"
    :table-props="tableProps"
    :show-action-bar="false"
    :filters="[searchDef]"
    style="width: 100%"
    :current-page="currentPage"
    :total="totalUsers"
    v-loading="loading"
  >
    <el-row class="table-header" slot="tool">
      <el-col class="table-header-name">
        <span>Users</span>
      </el-col>
      <el-col class="table-header-container">
        <div style="flex: 1">
          <el-input
            v-model="searchDef.value"
            placeholder="Search By Name..."
            size="small"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div style="margin-left: 1rem; flex: none">
          <el-button type="primary" size="small" @click="openCreateUserModal">
            New
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-table-column label="" width="60" align="center">
      <template slot-scope="scope">
        <span v-if="scope.row.pending">
          <el-tooltip
            effect="dark"
            content="Account pending activation."
            placement="bottom"
          >
            <font-awesome-icon
              icon="exclamation-circle"
              fixed-width
            ></font-awesome-icon>
          </el-tooltip>
        </span>
        <span v-else-if="!scope.row.active">
          <el-tooltip
            effect="dark"
            content="Account suspended."
            placement="bottom"
          >
            <font-awesome-icon
              icon="pause-circle"
              fixed-width
            ></font-awesome-icon>
          </el-tooltip>
        </span>
        <span v-else>
          <el-tooltip
            effect="dark"
            content="Account active."
            placement="bottom"
          >
            <font-awesome-icon
              icon="check-circle"
              fixed-width
            ></font-awesome-icon>
          </el-tooltip>
        </span>
      </template>
    </el-table-column>

    <el-table-column
      prop="name"
      label="Name"
      sortable="custom"
      min-width="150"
      show-overflow-tooltip
    ></el-table-column>

    <el-table-column
      prop="email"
      label="Email"
      sortable="custom"
      min-width="150"
      show-overflow-tooltip
    ></el-table-column>

    <el-table-column prop="role" label="Role" sortable="custom" width="170">
      <template slot-scope="scope">
        <span v-text="roleName(scope.row.role)"></span>
      </template>
    </el-table-column>

    <el-table-column
      prop="created_at"
      label="Created"
      sortable="custom"
      width="180"
    >
      <template slot-scope="scope">
        <span
          v-text="
            dateTimeFormat(scope.row.created_at, 'MM/DD/YYYY [at] hh:mm A')
          "
        ></span>
      </template>
    </el-table-column>

    <el-table-column
      prop="last_login_at"
      label="Last Login"
      sortable="custom"
      width="190"
    >
      <template slot-scope="scope">
        <span
          v-text="
            dateTimeFormat(scope.row.last_login_at, 'MM/DD/YYYY [at] hh:mm A')
          "
        ></span>
      </template>
    </el-table-column>

    <el-table-column label="" width="60" align="center">
      <template slot-scope="scope">
        <el-dropdown trigger="click" @command="userDropdown($event, scope.row)">
          <span class="el-dropdown-link">
            <el-button type="text" style="padding: 0" size="small">
              <font-awesome-icon
                icon="ellipsis-v"
                fixed-width
              ></font-awesome-icon>
            </el-button>
          </span>
          <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
            <el-dropdown-item
              command="edit"
              :disabled="
                scope.row.slug === 'super-user' ||
                (scope.row.slug === auth.slug && !$auth.check(['super']))
              "
            >
              <font-awesome-icon
                icon="pencil-alt"
                fixed-width
              ></font-awesome-icon>
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              command="activation"
              :disabled="
                scope.row.slug === 'super-user' ||
                (scope.row.slug === auth.slug && !$auth.check(['super']))
              "
              v-if="scope.row.pending"
            >
              <font-awesome-icon
                icon="envelope"
                fixed-width
              ></font-awesome-icon>
              Resend Activation Email
            </el-dropdown-item>
            <el-dropdown-item
              command="suspend"
              :disabled="
                scope.row.slug === 'super-user' ||
                (scope.row.slug === auth.slug && !$auth.check(['super']))
              "
              v-if="!scope.row.pending && scope.row.active"
            >
              <font-awesome-icon icon="lock" fixed-width></font-awesome-icon>
              Suspend
            </el-dropdown-item>
            <el-dropdown-item
              command="unsuspend"
              :disabled="
                scope.row.slug === 'super-user' ||
                (scope.row.slug === auth.slug && !$auth.check(['super']))
              "
              v-else-if="!scope.row.pending && !scope.row.active"
            >
              <font-awesome-icon
                icon="lock-open"
                fixed-width
              ></font-awesome-icon>
              Unsuspend
            </el-dropdown-item>
            <el-dropdown-item
              command="delete"
              :disabled="
                scope.row.slug === 'super-user' ||
                (scope.row.slug === auth.slug && !$auth.check(['super']))
              "
            >
              <font-awesome-icon
                icon="trash-alt"
                fixed-width
              ></font-awesome-icon>
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="20" class="pagination"
      layout="prev, pager, next" :total="totalUsers">
    </el-pagination>
</div>
</template>

<style scoped>
  .pagination {
  text-align: center;
}

.table-title {
  display: flex;
  border-bottom: 2px solid #d4a100;
  padding-right: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #303133;
}

.table-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-toolbar {
  display: flex;
  justify-content: flex-end;
  width: 500px;
  max-width: 100%;
  background: #fafafa;
  padding: .25rem .5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}
</style>

<script>
import UserCreateForm from "@/components/Forms/UserCreateForm"
import UserEditForm from "@/components/Forms/UserEditForm"
import { debounce } from 'lodash'
import DateTime from "@/mixins/DateTime"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "UserTable",

  mixins: [DateTime],

  data() {
    return {
      searchDef: {
        prop: "name",
        value: "",
      },

      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "name",
          order: "ascending",
        },
      },
      loading: false,
      currentPage: 1,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      data: "users/list",
      totalUsers: "users/totalUsers",
      roles: "users/roles",
      auth: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      refreshUsers: "users/refresh",
      updateUser: "users/update",
      removeUser: "users/remove",
    }),
    fetchData(page) {
      this.refreshUsers({ page, query: this.search });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchData(this.currentPage);
    }, 500),
    openCreateUserModal() {
      this.$modal.show(
        UserCreateForm,
        {},
        {
          name: "user-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    userDropdown(command, user) {
      if (command === "edit") {
        this.$modal.show(
          UserEditForm,
          {
            existing: user,
          },
          {
            name: "user-edit-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      } else if (command === "activation") {
        this.$confirm("Resend activation email to " + user.name + "?", "", {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          callback: (action) => {
            if (action === "confirm") {
              this.axios
                .post("users/activation/" + user.slug, {})
                .then((response) => {
                  this.$message.success(response.data.message)
                })
                .catch((resp) => {
                  this.$root.globalRequestError(resp)
                })
            }
          },
        }).catch(() => {})
      } else if (command === "suspend" || command === "unsuspend") {
        this.$confirm(
          command.charAt(0).toUpperCase() +
            command.slice(1) +
            " " +
            user.name +
            "?",
          "",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: command === "suspend" ? "warning" : null,
            callback: (action) => {
              if (action === "confirm") {
                this.axios
                  .post("users/suspend/" + user.slug, {})
                  .then((response) => {
                    this.updateUser(response.data.data)
                    this.$message.success(response.data.message)
                  })
                  .catch((resp) => {
                    this.$root.globalRequestError(resp)
                  })
              }
            },
          }
        ).catch(() => {})
      } else if (command === "delete") {
        this.$confirm(
          "Delete " + user.name + "? This action cannot be undone.",
          "",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "error",
            callback: (action) => {
              if (action === "confirm") {
                this.axios
                  .post("users/delete/" + user.slug, {})
                  .then((response) => {
                    this.removeUser(response.data.data)
                    this.$message.success(response.data.message)
                  })
                  .catch((resp) => {
                    this.$root.globalRequestError(resp)
                  })
              }
            },
          }
        ).catch(() => {})
      }
    },
    roleName(slug) {
      let role = this.$_.find(this.roles, function (o) {
        return o.slug === slug
      })

      return role ? role.name : ""
    },
  },

  watch: {
    data() {
      this.loading = false
    },
  },

  created() {
    this.loading = true
    this.fetchData(this.currentPage);
  },
}
</script>
